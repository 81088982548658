<template>
  <div class="mdl-cell mdl-cell--12-col-phone mdl-cell--12-col">
    <div :class="simplified ? '' : 'infoKit green'">
      <div :class="simplified ? '' : 'mdl-grid'">
        <!-- LEFT COLUMN START -->
        <div
          class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          v-if="!simplified"
        >
          <h3>
            {{ $t("crossConnects.crossConnectInActivation") }}
          </h3>
          <!-- ITEM TITLE -->

          <div class="mdl-grid" style="padding-left: 0px; align-items: center">
            <div
              class="mdl-cell mdl-cell--2-col contentImage"
              style="margin-left: 0px"
            >
              <img
                src="@/assets/img/icon_kit_one.png"
                alt="location"
                width="45"
              />
            </div>
            <div class="mdl-cell mdl-cell--10-col" style="margin-left: 0px">
              <h3 class="textOne" style="margin-bottom: 0">
                <slot name="itemDescription"></slot>
              </h3>
            </div>
          </div>
          <!-- ITEM TITLE END -->
          <hr />
          <!-- ADDRESS START -->
          <div class="mdl-grid" style="padding-left: 0px">
            <div
              class="mdl-cell mdl-cell--2-col contentImage"
              style="margin-left: 0px"
            >
              <img src="@/assets/img/icon_pin.png" alt="location" width="45" />
            </div>
            <div class="mdl-cell mdl-cell--10-col">
              <span class="labelTwo">{{ $t("orders.address") }}</span>
              <p class="textOne">
                <slot name="address"></slot>
              </p>
            </div>
          </div>
          <!-- ADDRESS END -->

          <hr />
          <!-- PRICES START -->
          <div class="mdl-grid" style="padding-left: 0px">
            <div
              class="mdl-cell mdl-cell--2-col contentImage"
              style="margin-left: 0px"
            >
              <img
                src="@/assets/img/icon_kit_price.png"
                alt="icon"
                width="45"
              />
            </div>
            <div class="mdl-cell mdl-cell--10-col">
              <div style="display: flex; justify-content: space-between">
                <div>
                  <span class="labelTwo">{{
                    $t("crossConnects.activationPrice")
                  }}</span>
                </div>
                <div>
                  <span class="detailThree" style="text-align: right"
                    >€ <slot name="nrc"></slot
                  ></span>
                </div>
              </div>

              <div style="display: flex; justify-content: space-between">
                <div>
                  <span class="labelTwo">{{
                    $t("crossConnects.priceMonth")
                  }}</span>
                </div>

                <div>
                  <span class="detailThree" style="text-align: right"
                    >€
                    <slot name="mrc"></slot>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- PRICES END -->

        <div v-if="simplified">
          <div>
            <!-- SINGLE COLUMN FOR SIMPLIFIED VIEW -->
            <div
              class="mdl-cell--10-col mdl-cell--10-col-tablet mdl-cell--12-col-phone"
            >
              <span class="simplified-title labelTwo">
                {{ $t("crossConnects.crossConnectInActivation") }}
              </span>

              <!-- ITEM TITLE -->
              <p class="textOne simplified-label" style="margin-bottom: 0">
                <slot name="itemDescription"></slot>
              </p>

              <!-- ADDRESS -->
              <span class="labelTwo simplified-title">{{
                $t("orders.address")
              }}</span>
              <p class="textOne simplified-label">
                <slot name="address"></slot>
              </p>

              <!-- PRICES -->
              <span class="labelTwo simplified-title">{{
                $t("crossConnects.activationPrice")
              }}</span>
              <span
                class="detailThree simplified-label"
                style="text-align: right"
              >
                € <slot name="nrc"></slot>
              </span>

              <div style="display: flex; justify-content: start">
                <span class="labelTwo margin-15 simplified-title">{{
                  $t("crossConnects.priceMonth")
                }}</span>

                <span
                  class="detailThree simplified-label"
                  style="text-align: right"
                >
                  € <slot name="mrc"></slot>
                </span>
              </div>

              <!-- CONTRACT DETAILS -->
              <h3 class="simplified-label">
                {{ $t("crossConnects.contractDetails") }}
              </h3>
              <div
                v-if="basePrice?.mrc != -1"
                class="tablePrice row-gap-0"
                style="
                  display: flex;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  row-gap: 1.5rem;
                  margin: 0;
                "
              >
                <div :class="!isMobile ? 'row' : 'column'">
                  <span class="labelTwo margin-15 simplified-title">
                    {{ $t("crossConnects.serviceDuration") }}
                  </span>
                  <div
                    :style="[
                      isMobile ? 'text-align:right' : 'text-align:center',
                    ]"
                  >
                    <span class="detailThree simplified-label">
                      <slot name="min_duration"></slot>
                    </span>
                  </div>
                </div>
                <div :class="!isMobile ? 'row' : 'column'">
                  <span class="labelTwo margin-15 simplified-title">
                    {{ $t("crossConnects.renewal") }}
                  </span>
                  <div
                    :style="[
                      isMobile ? 'text-align:right' : 'text-align:center',
                    ]"
                  >
                    <span class="detailThree simplified-label">
                      <slot name="renewal"></slot> </span
                    ><br />
                  </div>
                </div>
                <div :class="!isMobile ? 'row' : 'column'">
                  <span
                    class="labelTwo margin-15 simplified-title"
                    style="margin-right: 20px"
                  >
                    {{ $t("crossConnects.billingOption") }}
                  </span>
                  <div
                    :style="[isMobile ? 'width: auto' : '']"
                    style="text-align: left"
                  >
                    <span class="detailThree simplified-label">
                      {{
                        $t("crossConnects.anticipatedThrimester") +
                        " " +
                        $t("crossConnects.anticipated")
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- /SINGLE COLUMN END-->
          </div>
        </div>

        <!-- /LEFT COLUMN END-->
        <!-- SEPARATOR START -->
        <div class="mdl-cell mdl-cell--2-col"></div>
        <!-- SEPARATOR END -->

        <!-- RIGHT COLUMN START -->
        <div
          class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          v-if="!simplified"
        >
          <h3>{{ $t("crossConnects.contractDetails") }}</h3>
          <div
            v-if="basePrice?.mrc != -1"
            class="tablePrice"
            style="
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              row-gap: 1.5rem;
            "
          >
            <div :class="!isMobile ? 'row' : 'column'">
              <div class="contentImage">
                <img
                  v-show="!isMobile"
                  src="@/assets/img/duration.png"
                  alt="icon"
                  width="43"
                />
                <span class="detailOne textBlu">
                  {{ $t("crossConnects.serviceDuration") }}
                </span>
              </div>
              <div
                :style="[isMobile ? 'text-align:right' : 'align-self:center']"
              >
                <span class="detailThree"
                  ><slot name="min_duration"></slot>
                </span>
              </div>
            </div>
            <div :class="!isMobile ? 'row' : 'column'">
              <div class="contentImage">
                <img
                  v-show="!isMobile"
                  src="@/assets/img/renewal.png"
                  alt="icon"
                  width="43"
                />
                <span class="detailOne textBlu">
                  {{ $t("crossConnects.renewal") }}
                </span>
              </div>
              <div
                :style="[isMobile ? 'text-align:right' : 'align-self:center']"
              >
                <span class="detailThree"><slot name="renewal"></slot></span
                ><br />
              </div>
            </div>
            <div :class="!isMobile ? 'row' : 'column'">
              <div class="contentImage">
                <InfoFatturaIcon
                  v-show="!isMobile"
                  style="margin-right: 1rem"
                  width="43"
                  height="43"
                />
                <span class="detailOne textBlu" style="margin-right: 20px">
                  {{ $t("crossConnects.billingOption") }}
                </span>
              </div>
              <div
                class="value"
                :style="[isMobile ? 'width: auto' : 'width: min-content']"
                style="text-align: right"
              >
                <span class="detailThree"
                  >{{ $t("crossConnects.anticipatedThrimester") }}
                  <small class="detailTwo">{{
                    $t("crossConnects.anticipated")
                  }}</small></span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- RIGHT COLUMN END -->
      </div>
    </div>
  </div>
</template>

<script>
import InfoFatturaIcon from "@/components/OrderNetworkCoverage/svg/cross-svg/InfoFatturaIcon.vue";

export default {
  name: "CrossConnectSummaryBox",
  components: {
    InfoFatturaIcon,
  },
  props: {
    basePrice: Object,
    isMobile: Boolean,
    simplified: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/Kit.scss";
@import "@/assets/scss/CrossConnect.scss";

.simplified-title {
  font-size: 12px !important;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "Arial",sans-serif !important;
  line-height: 1.5 !important;  /* Increase line height */
  font-weight: 400 !important; /* or another consistent weight */
  letter-spacing: 2px !important;  /* Increase letter spacing */
}
.simplified-label {
  font-size: 15px !important;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "Arial",sans-serif !important;
  line-height: 1.5 !important;  /* Increase line height */
  letter-spacing: 2px !important;  /* Increase letter spacing */
  font-weight: 400 !important; /* or another consistent weight */

}
.simplified-padding {
  padding: 0 !important;
}
.mdl-cell-print {
  margin: 0 !important;
}

.margin-15 {
  margin-right: 15px !important;
}
.padding-0 {
  padding: 0 !important;
}

.row-gap-0 {
  row-gap: 0.5rem !important;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

</style>
